import req from "../utils/req"


//获取商品类目list
export function categoryList_api(params) {
    return req.get(`/app/otaHotel/index/getProductCateList`,params )
}

//获取轮播图
export function BannerList_api(params) {
    return req.get(`/app/otaHotel/index/getAdList`,params )
}

//获取可领取的优惠券列表
export function cangetCoupon_api(params) {
   // return req.get(`/app/otaHotel/index/getCouponList`,params )
    return req.get(`/app/otaHotel/order/getCouponList`,params )
}

//领取优惠券
export function getCoupon_api(params) {
   // return req.get(`/app/otaHotel/index/receive`,params )
    return req.get(`/app/otaHotel/order/receive`,params )
}


//上报访问
export function handerAccess_api(params) {
    // return req.get(`/app/otaHotel/index/receive`,params )
     return req.get(`/app/otaHotel/index/access`,params )
 }

