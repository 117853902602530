<template>
  <div class="content">
    <!-- <div class="zhanweilayout"></div> -->
    <van-sticky>
      <div class="headnav">
        <div class="headleftbox">
          <img class="searchicon" src="../assets/imgs/sousuo@3x.png" alt @click="openSearchOverlay" />
          <img class="caricon" src="../assets//imgs//cart.png" style="margin-left: 10px;" alt="" @click="toCart">
        </div>
        <!-- <div @click="openLoginDia('login')" v-if="!isLogin">登录</div>
        <div @click="loginOutFn" v-else-if="isLogin">退出</div>
        <div v-if="!isLogin" @click="openLoginDia('jion')">加入我们</div>
        <div v-else-if="isLogin" @click="toVipPage">设置</div>
        <div @click="toCart">购物车</div>
        <div @click="toOrderList">订单</div>-->

        <div class="logobox">
          <img class="logoimg" @click="changeCategory('')" src="../assets/imgs/logo@3x.png" alt />
        </div>
        <div>
          <div style="width: 41px;text-align: right;"><img class="menuicon" src="../assets/imgs/bar-zhankai@3x.png" alt
              @click="openNavOverlay" /></div>

        </div>
      </div>
    </van-sticky>
    <div>
      <!-- <router-view ref="child"></router-view> -->
      <keep-alive>
        <router-view ref="child" v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view ref="child" v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <van-overlay :show="showSearchOverlay">
      <div>
        <!-- <div class="zhanwei-search-box"></div> -->
        <div class="search-box-bg">
         
          <div class="search-box">
            <!-- <form action>
              <van-search
                v-model="searchValue"
                show-action
                action-text="关闭"
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @cancel="onCancel"
              />
            </form>-->
            <!-- <van-form action="">
              <van-search v-model="searchValue" show-action action-text="关闭" placeholder="请输入搜索关键词" @search="onSearch"
              @cancel="onCancel" background="transparent" class="search-box-bg-input" />
            </van-form> -->

            <van-form action="">
              <van-search v-model="searchValue" show-action placeholder="请输入搜索关键词" @search="onSearch"
                background="transparent" class="search-box-bg-input">
                <template slot="action">
                  <div @click="onSearch">搜索</div>
                </template>

              </van-search>
            </van-form>


            <!-- <input type="text" class="search-box-bg-input"> -->
          </div>
          <div>
            <div class="search-list-box" v-for="(item, index) in searchList" :key="index" @click="setSearchVal(item)">
              <span style="margin-left: 20px">{{ item }}</span>
            </div>
          </div>
          <div class="clearsearchbtn" @click="clearSearch">清空历史</div>
          <div class="closebox">
            <van-icon name="cross" size="20px" @click="closeSearchOverlay" />
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- <van-overlay :show="showNavOverlay" :lock-scroll="false">
      <div>
      
        <div class="nav-box-bg">
          <div class="zhanwei-nav-box"></div>
          <div class="zhanwei-nav-box2">
            <img class="navcloseimg" src="../assets/imgs/guanbi@3x.png" alt @click="closeNav" />
          </div>
          <div class="nav-logo-box">
            <img
              class="nav-logo-box"
              @click="changeCategory('')"
              src="../assets/imgs/logo1@3x.png"
              alt
            />
          </div>
          <div class="margin-top39">
            <div class>
              <div class="margin-bottom30" @click="showShop=!showShop">
                商品
                <span v-if="!showShop">+</span>
                <span v-if="showShop">-</span>
              </div>

              <div v-if="showShop" class="transitionbox">
                <div
                  class="margin-bottom30"
                  v-for="item in categoryList"
                  :key="item.id"
                  @click="changeCategory(item)"
                >{{ item.cateName }}</div>
              </div>
            </div>
          </div>
          <div class="margin-top39" @click="toVipPage">VIP</div>
          <div class="margin-top39" @click="toBulletinBoard">公告栏</div>
          <div class="margin-top39" @click="toAboutus">关于我们</div>
          <div class="margin-top100" v-if="!isLogin" @click="openLoginDia('login')">登录</div>
          <div class="margin-top39" v-if="!isLogin" @click="openLoginDia('login')">登录</div>
          <div class="margin-top39" v-if="isLogin" @click="loginOutFn">退出</div>
          <div class="margin-top39" @click="toMine">我的主页</div>
          <div class="margin-top39" @click="toOrderList">订单</div>
          <div class="margin-top39" @click="toCart">购物车</div>
          <div class="margin-top39" @click="toPurchasenotes">购买须知</div>
          <div class="margin-top39" @click="toPostsalesservice">售后服务</div>
          <div class="margin-top39" @click="toComplaintservice">投诉通道</div>
         
        </div>
      </div>
    </van-overlay> -->
    <div class="navoverlay" v-if="showNavOverlay">
      <div @click.stop>

        <div class="nav-box-bg">
          <div class="zhanwei-nav-box"></div>
          <div class="zhanwei-nav-box2">
            <img class="navcloseimg" src="../assets/imgs/guanbi@3x.png" alt @click="closeNav" />
          </div>
          <div class="nav-logo-box">
            <img class="nav-logo-box" @click="changeCategory('')" src="../assets/imgs/logo1@3x.png" alt />
          </div>
          <div class="margin-top39">
            <div class>
              <div class="margin-bottom30" @click="showShop = !showShop">
                商品
                <span v-if="!showShop">+</span>
                <span v-if="showShop">-</span>
              </div>

              <div v-if="showShop" class="transitionbox">
                <div class="margin-bottom30" v-for="item in categoryList" :key="item.id" @click="changeCategory(item)">
                  {{ item.cateName }}</div>
              </div>
            </div>
          </div>
          <div class="margin-top39" @click="toVipPage">VIP</div>
          <div class="margin-top39" @click="toBulletinBoard">公告栏</div>
          <div class="margin-top39" @click="toAboutus">关于我们</div>

          <div class="margin-top39" @click="toMine">我的主页</div>
          <div class="margin-top39" @click="toOrderList">订单</div>
          <div class="margin-top39" @click="toCart">购物车</div>
          <div class="margin-top39" @click="toPurchasenotes">购买须知</div>
          <div class="margin-top39" @click="toPostsalesservice">售后服务</div>
          <div class="margin-top39" @click="toComplaintservice">温馨提示</div>
          <div class="margin-top39" @click="showNoLogin" v-if="!isLogin">联系客服</div>
          <div class="margin-top39 po_r" v-if="isLogin">联系客服
            <button v-if="isLogin" class="footkefubtntwo" onclick="qimoChatClick();">1</button>
          </div>
          <!-- <div class="margin-top100" v-if="!isLogin" @click="openLoginDia('login')">登录</div> -->
          <div class="margin-top39" v-if="!isLogin" @click="openLoginDia('login')">登录</div>
          <div class="margin-top39" v-if="isLogin" @click="loginOutFn">退出</div>

        </div>
      </div>
    </div>
    <Logindia ref="logindia"></Logindia>
    <Cangetcoupondia ref="cangetcoupondia"></Cangetcoupondia>

    <div class="footkefubox">
      <img @click.stop="showNoLogin" class="footkefuimg" src="../assets/imgs/huabg.png" alt />
      <button v-if="isLogin" class="footkefubtn" onclick="qimoChatClick();">1</button>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local.js";
import Logindia from "../components/Logindia.vue";
import Cangetcoupondia from "../components/Cangetcoupondia.vue";
import session from "../utils/session";
import { categoryList_api, handerAccess_api } from "../apis/layout";
import { userInfo_api } from "../apis/mine";
import { imOrderInfo_api } from "../apis/order";

export default {
  components: { Logindia, Cangetcoupondia },
  data() {
    return {
      showSearchOverlay: false,
      searchValue: "",
      searchList: [],
      showNavOverlay: false,
      isLogin: false,
      categoryList: [],
      categoryId: "",
      cangetCouponList: [],
      showShop: false,
    };
  },
  created() {
    this.getUserLogin();
    this.getCategoryList();
    this.getUserInfo();
  },
  mounted() {
    this.$bus.$on("searchCategoryInfo", e => {
      this.categoryId = e.id;
      this.$router.push(
        {
          path: "/index",
          query: {
            searchCategoryId: e.id
          }
        },
        () => { }
      );
    });
    this.$nextTick(() => {
      this.getCangetCoupon();
      this.getImOrderInfo();
    }, 1000);
  },

  methods: {
    openSearchOverlay() {
      if (local.get("searchList")) {
        this.searchList = JSON.parse(local.get("searchList"));
      }
      this.showSearchOverlay = true;
    },
    onSearch(val) {
      console.log(val)
      console.log(this.searchValue)


      if (this.searchValue) {
        if (local.get("searchList")) {
          let searchList = JSON.parse(local.get("searchList"));
          searchList.unshift(this.searchValue);

          let newSearchList = JSON.stringify(Array.from(new Set(searchList)));
          local.set("searchList", newSearchList);
        } else {
          local.set("searchList", "[]");
          let searchList = [];
          searchList.unshift(this.searchValue);
          let newSearchList = JSON.stringify(Array.from(new Set(searchList)));
          local.set("searchList", newSearchList);
        }
      }
      
      this.showSearchOverlay = false;

      //console.log(this.$route.path=='/index')
      if (this.$route.path == "/index") {
        //  this.$parent.searchData.key=val
        this.$refs.child.searchFntwo(this.searchValue);
      } else {
        this.$router.push(
          {
            path: "/index",
            query: {
              searchValue: this.searchValue
            }
          },
          () => { }
        );
      }
    },
    setSearchVal(val) {
      this.searchValue = val;
    },
    onCancel() {
      this.showSearchOverlay = false;
    },
    closeSearchOverlay(){
      this.showSearchOverlay = false;
    },
    clearSearch() {
      this.searchList = [];
      if (local.get("searchList")) {
        local.remove("searchList");
      }
    },
    openNavOverlay() {
      this.showNavOverlay = true;
    },
    closeNav() {
      this.showNavOverlay = false;
    },
    openLoginDia(type) {
      this.showNavOverlay = false;
      this.$refs.logindia.open(type);
    },
    toCart(val) {
      if (this.isLogin) {
        this.$router.push(
          {
            path: "/cart",
            query: {
              searchValue: val
            }
          },
          () => { }
        );
      } else {
        this.openLoginDia("login");
      }
      this.showNavOverlay = false;
    },
    toMine(val) {
      if (this.isLogin) {
        this.$router.push(
          {
            path: "/mine",
            query: {
              searchValue: val
            }
          },
          () => { }
        );
      } else {
        this.openLoginDia("login");
      }

      this.showNavOverlay = false;
    },
    toOrderList(val) {
      if (this.isLogin) {
        this.$router.push(
          {
            path: "/orderlist",
            query: {
              searchValue: val
            }
          },
          () => { }
        );
      } else {
        this.openLoginDia("login");
      }

      this.showNavOverlay = false;
    },
    toBulletinBoard() {
      this.$router.push(
        {
          path: "/bulletinboard"
        },
        () => { }
      );
      this.showNavOverlay = false;
    },
    getUserLogin() {
      if (local.get("tk") || session.get("tk")) {
        this.isLogin = true;
        this.getUserInfo();
      } else {
        this.handerAccess()
      }
    },
    async getUserInfo() {
      let { code, data } = await userInfo_api();
      if (code == 0) {
        this.$bus.$emit("userInfo", data);
        window.qimoClientId.userId = data.id;
        if (data.nickName) {
          window.qimoClientId.nickName = data.nickName;
        } else {
          window.qimoClientId.nickName = data.telephone;
        }
        this.handerAccess(data.id)
      }
    },
    async handerAccess(id) {
      let accessId = id ? id : ''
      let { code, data } = await handerAccess_api({
        id: accessId
      });
      if (code == 0) {
        console.log("indexAccess");
      }
    },
    loginOutFn() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定退出登录吗？"
        })
        .then(() => {
          if (local.get("tk")) {
            local.remove("tk");
          }
          if (session.get("tk")) {
            session.remove("tk");
          }
          this.isLogin = false;
          this.$router.push(
            {
              path: "/index"
            },
            () => { }
          );
        })
        .catch(() => {
          console.log("取消了");
        });
      this.showNavOverlay = false;
    },
    async getCategoryList() {
      let { code, data } = await categoryList_api();
      if (code == 0) {
        this.categoryList = data;
      }
    },
    changeCategory(item) {
      this.categoryId = item.id;
      this.$bus.$emit("searchCategoryInfo", item);
      if (this.$route.path == "/index") {
        this.$refs.child.searchFnThree(item.id);
      } else {
        this.$router.push(
          {
            path: "/index",
            query: {
              searchCategoryId: item.id
            }
          },
          () => { }
        );
      }

      this.showNavOverlay = false;
    },
    toVipPage(val) {
      if (this.isLogin) {
        this.$router.push(
          {
            path: "/vippage",
            query: {
              searchValue: val
            }
          },
          () => { }
        );
      } else {
        // this.$dialog.alert({
        //   message: "你还不是会员"
        // });
        this.openLoginDia("login");
      }
      this.showNavOverlay = false;
    },
    showNoLogin() {
      this.$dialog.alert({
        message: "请先登录！"
      });
    },
    toPurchasenotes() {
      this.showNavOverlay = false;
      this.$router.push(
        {
          path: "/purchasenotes"
        },
        () => { }
      );
    },
    toPostsalesservice() {
      this.showNavOverlay = false;
      this.$router.push(
        {
          path: "/postsalesservice"
        },
        () => { }
      );
    },
    toComplaintservice() {
      this.showNavOverlay = false;
      this.$router.push(
        {
          path: "/complaintservice"
        },
        () => { }
      );
    },
    toAboutus() {
      this.showNavOverlay = false;
      this.$router.push(
        {
          path: "/aboutus"
        },
        () => { }
      );

    },
    async getCangetCoupon() {
      this.$refs.cangetcoupondia.open();
    },
    async getImOrderInfo() {
      let imOrderId = null;
      this.$bus.$on("imOrderId", data => {
        imOrderId = data;
      });
      let { code, data } = await imOrderInfo_api({
        id: imOrderId
      });
      if (code == 0) {
        console.log(data);
        if (!data.id) {
          m7ProductInfo.visible = false;
        } else {
          m7ProductInfo.title = data.productList[0].productName;
          m7ProductInfo.img = data.productList[0].productMainImage;
          m7ProductInfo.price = "￥" + data.totalAmount;
          m7ProductInfo.attr_one = {
            color: "#000000",
            content: `X${data.num}`
          };
          let attrTwo = null;
          if (data.status == 0) {
            attrTwo = "待付款 ";
          } else if (data.status == 1) {
            attrTwo = "待发货 ";
          } else if (data.status == 2) {
            attrTwo = "待收货 ";
          } else if (data.status == 3) {
            attrTwo = "确认收货 ";
          } else if (data.status == 4) {
            attrTwo = "售后 ";
          } else if (data.status == 5) {
            attrTwo = "已取消 ";
          }
          m7ProductInfo.attr_two = { color: "#000000", content: attrTwo };
          m7ProductInfo.other_title_one = `订单号:${data.orderNum}`;
          m7ProductInfo.other_title_two = `下单时间:${data.createTime}`;
          console.log(m7ProductInfo);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;

  background: #eeeee2;
}

.zhanweilayout {
  width: 100%;
  height: 87px;
}

.headnav {
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 600;
  color: #333333;
  background: #eeeee2;
  height: 100px;
}

.headleftbox {
  display: flex;

  align-items: center;
}

.searchicon {
  width: 26px;
  height: 26px;
  position: relative;
  top: 3px;
}

.caricon {
  width: 32px;
  height: 32px;
}

.menuicon {
  width: 28px;
  height: 28px;
  position: relative;
  top: 5px;
}

.search-box-bg {
  width: 750px;
  // background-color: rgba(255, 255, 255, 0.3);
  background-color: #eeeee2;
  height: 100vh;
  position: relative;
}
.closebox{
  position: absolute;
  right: 50px;
  top: 50px;

}

.search-box {
  margin: 0 auto;
  width: 690px;
 
}

.zhanwei-search-box {
  width: 750px;
  height: 10vh;
}

.search-list-box {
  margin: 0 auto;
  width: 690px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #dddddd;
  color: #ccc;
}

.search-box-bg-input {
  background-color: #eeeee2;
  padding-top: 30vh;


}

.van-field__control {
  color: #ccc;
}

.van-cell__value--alone {
  color: #ccc !important;
}

.van-search__content {
  // background-color: #eeeee2;
  background-color: transparent;
  border-bottom: #333333 1px solid;
  color: #eee;
}

.clearsearchbtn {
  margin: 0 auto;
  margin-top: 22px;

  width: 142px;
  height: 62px;
  // background: #ffffff;
  // border: 2px solid #e3e3e3;
  line-height: 62px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ccc;
}

.zhanwei-nav-box {
  height: 80px;
  width: 100%;
}

.nav-box-bg {
  margin-left: 350px;
  width: 400px;
  // height: calc(100vh - 80px);
  height: 100vh;
  background: #986665;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 600;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0px 40px 80px;
  // overflow-y: auto;
  overflow-y: auto;
}

.navoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo-box {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}

.zhanwei-nav-box2 {
  height: 70px;
  width: 100%;
  position: relative;
}

.navcloseimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 31px;
  right: 0px;
}

.margin-top39 {
  margin-top: 39px;
}

.padding-left40 {
  margin-left: 40px;
}

.margin-left167 {
  margin-left: 167px;
}

.margin-left87 {
  margin-left: 87px;
}

.margin-left85 {
  margin-left: 85px;
}

.margin-left96 {
  margin-left: 96px;
}

.margin-left88 {
  margin-left: 88px;
}

.margin-top53 {
  margin-top: 53px;
}

.margin-bottom30 {
  margin-bottom: 30px;
  // text-align: center;
}

.margin-top70 {
  margin-top: 50px;
  text-align: center;
}

.kefuicon {
  width: 24px;
  height: 24px;
  display: inline;
  position: relative;
  top: 5px;
}

.margin-top40 {
  margin-top: 20px;
  text-align: center;
}

.margin-left10 {
  margin-left: 10px;
}

.morbtn {
  position: relative;
  top: -34px;
  opacity: 0;
}

.textcenter {
  text-align: center;
}

.logoimg {
  width: 130px;
  height: 48px;
}

.footkefubox {
  position: fixed;
  bottom: 150px;
  right: 14px;
  width: 88px;
  height: 88px;
  background-color: #fff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 10px;
}

.footkefuimg {
  width: 68px;
  height: 68px;
}

.footkefubtn {
  width: 88px;
  height: 88px;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
}

.shoptitle {
  text-align: center;
}

.margin-top100 {
  margin-top: 100px;
}

.transitionbox {
  transition: all 2s ease-in-out;
  font-size: 24px;
}

.po_r {
  position: relative;
}

.footkefubtntwo {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  width: 320px;
  height: 40px;

}
</style>