import req from "../utils/req"

//通过商品详情进入获取购物信息
export function buyInfoOne_api(params) {
    return req.get(`/app/otaHotel/order/addProductD`,params )
}
//切换地址之后调取运费
export function freightAmount_api(params) {
    return req.post(`/app/otaHotel/order/getFreightAmount`,params )
}
//通过购物车进入获取购物信息
export function buyInfoTwo_api(params) {
    return req.get(`/app/otaHotel/order/add`,params )
}

//创建订单
export function createBooking_api(params) {
    return req.post(`/app/otaHotel/order/booking`,params )
}

//模拟支付
export function testPay_pai(params) {
    return req.get(`/app/otaHotel/order/pay`,params )
}

//获取订单列表
export function orderList_api(params) {
    return req.get(`/app/otaHotel/order/list`,params )
}

//获取售后订单列表
export function saleOrderList_api(params) {
    return req.get(`/app/otaHotel/order/salelist`,params )
}

//获取订单详情
export function orderInfo_api(params) {
    return req.get(`/app/otaHotel/order/getOrderInfo`,params )
}
//获取售后订单详情
export function saleOrderInfo_api(params) {
    return req.get(`/app/otaHotel/order/getOrderAfterSaleInfo`,params )
}

//删除订单记录
export function delOrder_api(params) {
    return req.get(`/app/otaHotel/order/del`,params )
}
//删除售后订单记录
export function delSaleOrder_api(params) {
    return req.get(`/app/otaHotel/order/delSale`,params )
}
//取消订单
export function cancelOrder_api(params) {
    return req.get(`/app/otaHotel/order/cancel`,params )
}
//获取物流信息
// export function haisiyInfo_api(params) {
//     return req.get(`/app/otaHotel/order/getHaisiyInfo`,params )
// }
export function haisiyInfo_api(params) {
    return req.get(`/app/otaHotel/order/getHaisiyInfoNew`,params )
}
//确认收货
export function confirmOrder_api(params) {
    return req.get(`/app/otaHotel/order/confirm`,params )
}

//申请退款获取商品
export function saleProduct_api(params) {
    return req.get(`/app/otaHotel/order/getProductLists`,params )
}

//上传文件
export function uploadList_api(params) {
    return req.post(`/public/otaHotel/common/uploadList`,params )
}

//申请退单
export function addOrderAfterSale_api(params) {
    return req.post(`/app/otaHotel/order/addOrderAfterSale`,params )
}

//撤销退货退款
export function cancelSaledOrder_api(params) {
    return req.get(`/app/otaHotel/order/cancelOrderAfterSale`,params )
}

//退单填写物流信息
export function addWl_api(params) {
    return req.get(`/app/otaHotel/order/addWl`,params )
}

//获取未处理订单
export function getOrderNum_api(params) {
    return req.get(`/app/otaHotel/order/getNum`,params )
}
//支付宝支付
export function payOrderforAli_api(params) {
    return req.get(`/app/otaHotel/order/alipay`,params )
}

//微信支付（微信外）
export function payOrderforWXW_api(params) {
    return req.get(`/app/otaHotel/order/weixinpay`,params )
}

//获取订单详情Im用
export function imOrderInfo_api(params) {
    return req.get(`/app/otaHotel/order/getOrderInfoIm`,params )
}